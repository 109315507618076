<template>
    <v-app-bar flat app color="primary">
        <div class="d-flex align-center w-100">
            <!-- Left Section Of top Bar -->
            <section class="mr-auto hidden-xs-only ml-6">
                <v-text-field
                        rounded
                        dark
                        dense
                        outlined
                        :prepend-inner-icon="icons.mdiMagnify"
                        class="app-bar-search flex-grow-0"
                        hide-details/>
            </section>

            <!-- Right Section of Top Bar -->
            <section class="ml-auto">

                <v-btn class="mr-5" icon rounded dark color="white" @click="toggleLocale">
                    {{locale}}
                </v-btn>

                <v-btn dark icon small class="hidden-sm-and-up" @click="toggleSidebarMenu">
                    <v-icon>{{icons.mdiMenu}}</v-icon>
                </v-btn>



                <a @click="logout" class="hidden-xs-only">
                    <v-btn dark icon small class="mr-6">
                        <v-icon>
                            {{ icons.mdiLogout }}
                        </v-icon>
                    </v-btn>
                </a>
            </section>
        </div>
    </v-app-bar>
</template>
<script>
    import {mdiMagnify, mdiBellOutline, mdiGithub, mdiLogout, mdiMenu} from '@mdi/js'
    import {EventBus} from "@/bootstrap/EventBus";
    import {mapActions, mapGetters} from "vuex"

    export default {
        name: 'DefaultTopBar',
        data: () => ({
            icons: {
                mdiMagnify,
                mdiBellOutline,
                mdiGithub,
                mdiLogout,
                mdiMenu
            },
        }),
        methods: {
            /** Logout **/
            logout: function () {
                this.$store.dispatch('auth/purgeAuthUser').then(() => {
                    this.$router.push({name: 'login'});
                })
            },
            /** Locale **/
            ...mapActions(['setLocale']),
            toggleLocale: function () {
                this.setLocale(this.locale === 'en' ? 'de' : 'en');
            },
            /** Sidebar **/
            toggleSidebarMenu: function () {
                EventBus.$emit('toggle-sidebar-menu');
            }
        },
        computed: {
            ...mapGetters(['locale'])
        }
    }
</script>

<style>
    .w-100 {
        width: 100%;
    }
</style>
