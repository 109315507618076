// // @ts-ignore
import i18n from '@/bootstrap/i18n'

const getters = {
    locale: (state) => state.locale
};

const actions = {
    setLocale({commit}, payload) {
        i18n.locale = payload;
        commit('setLocale', payload);
    }
};

const mutations = {
    setLocale(state, value) {
        state.locale = value
        window.localStorage.setItem('locale', value);
    }
};

const state = {
    locale: 'en'
};

export const locale = {
    state,
    getters,
    actions,
    mutations
}
