<template>
    <!-- dark -->
    <v-col cols="6">
        <v-card>
            <v-card-title>Welcome, {{$store.getters['auth/user'].name}}</v-card-title>
            <v-card-subtitle>
                This is the {{$route.name}} Page
            </v-card-subtitle>
        </v-card>
    </v-col>
</template>


<script>
    export default {
        name: 'BlankPageContent'
    }
</script>
