import store from "@/store";

export default function auth(context: any) {
    const loginQuery = {name: "login", params: {redirect: context.to.fullPath}};

    if (!store.getters['auth/check']) {
        context.next(loginQuery);
    } else {
        context.next();
    }
}
