import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router'
import store from './store'
import vuetify from './plugins/vuetify'
import axiosInit from './bootstrap/axiosInit'
import Permissions from './plugins/Permissions'
import Notifications from 'vue-notification'

import VueSweetalert2 from 'vue-sweetalert2';
import sweetAlert from "@/plugins/sweetAlert";
import LebenColorDefinerPlugin from "@/plugins/LebenColorDefinerPlugin";

import VueDictaphone from "vue-dictaphone";

Vue.use(VueDictaphone);

Vue.use(VueSweetalert2, sweetAlert);

Vue.use(Notifications);

Vue.use(LebenColorDefinerPlugin);

axiosInit(Vue);
Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.mixin(Permissions);

/** I18n - Translation package **/
import i18n from '@/bootstrap/i18n'

 new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app')

