<template>
    <v-navigation-drawer
            :value="isDrawerOpen"
            app
            floating
            width="260"
            class="app-navigation-menu">

        <!-- Navigation Header -->
        <div class="vertical-nav-header d-flex items-center">
            <router-link to="/" class="d-flex align-center text-decoration-none">
                <v-img src="/img/logo.png"
                       max-height="120px"
                       max-width="240px"
                       alt="logo"
                       contain
                       eager
                       class="app-logo mt-1"
                ></v-img>
            </router-link>
        </div>

        <!-- Navigation Items -->

        <v-list expand nav flat shaped class="pr-5">
            <v-list-item-group color="primary" v-model="activeTab">
                <router-link v-if="authUserCanView(link)" :to="{name: link.to}" v-for="link in links" :key="link.title">
                    <v-list-item link :value="link.to">
                        <v-list-item-icon>
                            <v-icon class="mx-auto">{{ link.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="text-capitalize">{{$t(('navigation.'+link.name))}}</v-list-item-title>
                    </v-list-item>
                </router-link>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import {
        mdiAccountEditOutline,
        mdiAccountSettingsOutline,
        mdiAccountOutline,
        mdiCalendarAccount,
        mdiAccountCashOutline,
        mdiContactsOutline,
        mdiNoteAlertOutline,
        mdiHomeOutline,
        mdiProjectorScreenOutline
    } from "@mdi/js";
    import {mapGetters} from "vuex";
    import {EventBus} from "@/bootstrap/EventBus";

    export default {
        name: 'DefaultSideBar',
        data: () => ({
            isDrawerOpen: true,
            active: true,
            activeTab: null,
            links: [
                {name: 'dashboard', to: 'dashboard', icon: mdiHomeOutline, permissions: ['any']},
                {name: 'users', to: 'users', icon: mdiAccountOutline, permissions: ['users.view']},
                // {name: 'caretakers', to: 'caretakers', icon: mdiAccountOutline, permissions: ['users.view']},
                {name: 'rotations', to: 'rotations', icon: mdiContactsOutline, permissions: ['contracts.view']},
                {name: 'customers', to: 'customers', icon: mdiContactsOutline, permissions: ['customers.view']},
                {name: 'contracts', to: 'contracts', icon: mdiContactsOutline, permissions: ['contracts.view']},
                {name: 'tasks', to: 'tasks', icon: mdiNoteAlertOutline, permissions: ['tasks.view']},
                {name: 'invoices', to: 'invoices', icon: mdiNoteAlertOutline, permissions: ['invoices.view']},
                {name: 'settings', to: 'settings', icon: mdiAccountSettingsOutline, permissions: ['settings.view']},
                {name: 'profile', to: 'profile', icon: mdiAccountEditOutline, permissions: ['any']}
            ]
        }),
        computed: {
            ...mapGetters('auth', ['permissions']),
        },
        created: function () {
            this.activeTab = this.$route.name;

            EventBus.$on('toggle-sidebar-menu', () => {
                this.isDrawerOpen = !this.isDrawerOpen;
            })
        },
        methods: {
            authUserCanView: function (link) {
                return !!link.permissions.filter(permission => {
                    return this.permissions.includes(permission) || permission === 'any';
                }).length;
            },
        }
        ,
    }
</script>


<style>
    .app-navigation-menu * {
        text-decoration: none !important;
    }
</style>
