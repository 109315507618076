import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { auth } from './modules/auth'
import { locale } from './modules/locale'
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  modules: {
    auth,
    locale
  },
  plugins: [createPersistedState({key: 'lebenswerte'})],
})
