import Vue from 'vue'

export default {
    fetchUsers: function (filters = {}) {
        return Vue.$http.get('users', {
            params: {
                filters
            }
        });
    },
    updateUserRole: function (user, role_id) {
        return Vue.$http.patch('users/' + user.id + '/role', {role_id: role_id})
    },

    fetchUser: function (user_id) {
        return Vue.$http.get('users/' + user_id);
    },

    fetchAssigneesDictionary: function () {
        return Vue.$http.get('users/assignee/dictionary');
    },


    fetchNursesDictionary: function () {
        return Vue.$http.get('users/nurses/dictionary');
    },


    fetchUsersDictionary: function () {
        return Vue.$http.get('users/dictionary');
    },

}
