<template>
    <section id="users-list-page">
        <blank-page-content />
    </section>
</template>

<script>
    import BlankPageContent from "@/views/layout/components/BlankPageContent";

    export default {
        components: {BlankPageContent}
    }
</script>
