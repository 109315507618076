<template>
    <v-app>
        <!-- Main Content -->
        <v-main>
            <slot></slot>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: 'BlankLayout',
    }
</script>
