var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"d-flex gap-10 my-7"},[_c('h2',[_vm._v("Files")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":_vm.toggleUploader}},[_vm._v("+")])],1),(_vm.uploaderActive && !_vm.section)?_c('v-sheet',[_c('h2',[_vm._v("What do you want to upload?")]),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary"},on:{"click":function($event){_vm.section = 'audio'}}},[_vm._v("Audio")]),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){_vm.section = 'files'}}},[_vm._v("Files")]),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.section = 'image'}}},[_vm._v("Image")])],1):_vm._e(),(_vm.section === 'audio')?_c('div',[_c('div',{staticClass:"d-flex gap-10"},[_c('vue-dictaphone',{attrs:{"mime-type":"audio/wav"},on:{"stop":function($event){return _vm.handleRecording($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isRecording = ref.isRecording;
var startRecording = ref.startRecording;
var stopRecording = ref.stopRecording;
var deleteRecording = ref.deleteRecording;
return [(!isRecording)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":startRecording}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-record")]),_vm._v(" Start Recording ")],1):_c('v-btn',{staticClass:"mb-5",on:{"click":stopRecording}},[_vm._v("Stop")])]}}],null,false,1446077064)}),_c('vue-dictaphone-spectrum-analyser',{attrs:{"height":36,"fillStyle":"#9155fd"}})],1),(_vm.audioSource)?_c('v-sheet',{staticClass:"mt-10 d-block d-sm-flex"},[_c('audio',{attrs:{"src":_vm.audioSource,"controls":""}}),_c('v-btn',{staticClass:"ml-auto mt-2",attrs:{"color":"primary"},on:{"click":_vm.uploadAudio}},[_vm._v(" Upload Audio ")])],1):_vm._e()],1):_vm._e(),(_vm.section === 'image')?_c('div',{staticClass:"d-flex gap-10 mt-10"},[_c('v-file-input',{attrs:{"label":"Click to take Photo","outlined":"","prepend-icon":"mdi-camera","capture":"user","accept":"image/*"},model:{value:(_vm.imageFile),callback:function ($$v) {_vm.imageFile=$$v},expression:"imageFile"}}),_c('v-btn',{staticClass:"ml-auto",on:{"click":_vm.initiatePhotoUpload}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1):_vm._e(),(_vm.section === 'files')?_c('div',{staticClass:"d-flex gap-10 mt-10"},[_c('v-file-input',{attrs:{"multiple":"","outlined":"","label":"Attach Files"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,false,590729088),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('v-btn',{staticClass:"ml-auto",on:{"click":_vm.initiateUpload}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1):_vm._e(),(_vm.task.attachments && !_vm.uploaderActive)?_c('div',[(!_vm.task.attachments.length)?_c('p',[_vm._v("No Files Attached.")]):_vm._e()]):_vm._e(),(_vm.task.attachments)?_c('div',{staticClass:"mb-5 mt-10"},_vm._l((_vm.task.attachments),function(attachment){return _c('a',{attrs:{"target":"_blank","href":_vm.getAttachmentDownloadLink(attachment),"download":""}},[_c('v-btn',{staticClass:"mr-3 mb-3",attrs:{"small":""}},[_vm._v(_vm._s(attachment.name)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-download-outline")])],1)],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }