<template>
    <v-app>
        <section id="auth">
            <v-sheet class="d-flex mx-auto align-center mt-16" width="390">
                <v-img src="/img/logo.png"
                       max-width="390px"
                       alt="logo"
                       contain
                       eager
                       class="app-logo me-3"
                ></v-img>
            </v-sheet>

            <div class="d-flex mt-10">
                <v-sheet class="mx-auto px-8 py-10 rounded-lg" width="390" elevation="10">
                    <!-- Main Content -->
                    <v-main>
                        <slot></slot>
                    </v-main>
                </v-sheet>
            </div>
        </section>
    </v-app>
</template>

<script>
    export default {
        name: 'AuthLayout',
    }
</script>
