import {EventBus} from "@/bootstrap/EventBus";
import auth from "../api/modules/auth";

export default {
    methods: {
        can(name) {
            return !!this.$store.getters['auth/permissions'].filter(permission => {
                return permission === name
            }).length;
        },
        flushPermissions() {
            auth.me().then(res => {
                this.$store.dispatch("auth/saveAuthUser", res.data);
            }).catch(e => {
                EventBus.$emit('submit-error', e);
            });
        },
    },
};
