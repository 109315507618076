import axios from 'axios'
import VueAxios from 'vue-axios'
import store from "@/store/index.ts";

// eslint-disable-next-line no-unused-vars
export const install = (Vue, options = {}) => {
    const api = axios.create({
        'baseURL': process.env.VUE_APP_API_BASE_URL + '/api/',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    });

    api.defaults.headers.common['Authorization'] = (store.getters['auth/token'] ? 'Bearer ' + store.getters['auth/token'] : null);

    api.interceptors.request.use(async (config) => {
        if (store.getters['auth/token']) {
            config.headers = {
                'Authorization': `Bearer ${store.getters['auth/token']}`,
                ...config.headers
            }
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    });

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        function (error) {
            if (
                error.response &&
                [401, 419].includes(error.response.status) &&
                store.getters['auth/check']
            ) {
                store.dispatch('auth/logout');
            }
            return Promise.reject(error);
        }
    );

    Vue.use(VueAxios, api)
};

export default (Vue, options = {}) => {
    return install(Vue, options)
}
