<template>
    <section id="leben">
        <component :is="resolveLayout">
            <router-view></router-view>
        </component>
        <notifications position="bottom right"/>
    </section>
</template>

<script>
    import BlankLayout from '@/views/layout/blank/BlankLayout.vue'
    import AuthLayout from '@/views/layout/auth/AuthLayout.vue'
    import DefaultContentLayout from '@/views/layout/default/DefaultContentLayout.vue'

    export default {
        components: {
            BlankLayout,
            AuthLayout,
            DefaultContentLayout,
        },
        computed: {
            /** Layout Loader
             *
             * Loads Layout According to current Route Meta
             * Default fall-back
             * **/
            resolveLayout: function () {
                if (this.$route.name === null) return null;

                return this.$route.meta.layout ? this.$route.meta.layout : 'default-content-layout';
            }
        }
    }
</script>

<style lang="scss">
    * {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
    }

    ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    .gap-10 {
        gap: 20px;
    }

    label {
        background: white;
        padding: 0 10px;
        margin-top: -5px;
    }

    .v-input--checkbox {
        label {
            padding: 0;
            padding-right: 21px;
            margin-bottom: -4px;
        }
    }


    #leben {


        .vue-notification {
            padding: 21px;
            margin: 0 5px 5px;
            font-size: 16px;

            .notification-title {
                font-size:21px;
                margin-bottom:7px;
            }

            font-family: "Roboto", sans-serif;

            color: #ffffff;
            background: #44A4FC;
            border-left: 5px solid #187FE7;

            &.warn {
                background: #ffb648;
                border-left-color: #f48a06;
            }

            &.error {
                background: #E54D42;
                border-left-color: #B82E24;
            }

            &.success {
                background: #9155fd;
                border-left-color: #663db3;
            }
        }
    }

    #task-view-popup {
        label {
            padding: 0;
            font-size: 12px;
            color: #333;
        }
        h1 {
            font-size: 42px;
            font-weight: 900;
        }
        h2 {
            margin-bottom: 10px;
            margin-top: -5px;
        }
        p {
            font-weight: bold;
        }
    }
</style>
