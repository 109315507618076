import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import middlewarePipeline from "@/router/middlewarePipeline";
import auth from "@/router/middleware/auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/auth/login/Login.vue'),
        meta: {
            layout: 'auth-layout'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/pages/auth/register/Register.vue'),
        meta: {
            layout: 'auth-layout'
        }
    },
    {
        path: '/caretakers',
        name: 'caretakers',
        component: () => import('@/views/pages/caretakers/list/CaretakersList.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/pages/users/list/UsersList.vue'),
        meta: {
            middleware: [auth]
        }
    },
    /** Customers **/
    {
        path: '/customers',
        name: 'customers',
        component: () => import('@/views/pages/customers/list/CustomersList.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/customers/create',
        name: 'customers.create',
        component: () => import('@/views/pages/customers/create/CustomersCreate.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/users/:id',
        name: 'users.show',
        component: () => import('@/views/pages/users/show/UsersShow.vue'),
        redirect: '/users/:id/profile',
        meta: {
            middleware: [auth]
        },
        children: [
            {
                path: '/users/:id/profile',
                name: 'users.profile',
                component: () => import('@/views/pages/users/show/profile/UsersProfile.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/users/:id/tasks',
                name: 'users.tasks',
                component: () => import('@/views/pages/users/show/profile/components/UsersTasks.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/users/:id/caretaker-profile',
                name: 'users.caretaker-profile',
                component: () => import('@/views/pages/users/show/profile/components/UsersCaretakerProfile.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/users/:id/invoices',
                name: 'users.invoices',
                component: () => import('@/views/pages/users/show/profile/components/UsersInvoices.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/users/:id/attachments',
                name: 'users.attachments',
                component: () => import('@/views/pages/users/show/profile/components/UsersAttachments.vue'),
                meta: {
                    middleware: [auth]
                },
            },
        ],
    },
    {
        path: '/customers/:id',
        name: 'customers.show',
        component: () => import('@/views/pages/customers/show/CustomersShow.vue'),
        redirect: '/customers/:id/general',
        meta: {
            middleware: [auth]
        },
        children: [
            {
                path: '/customers/:id/general',
                name: 'customers.general-information',
                component: () => import('@/views/pages/customers/show/components/CustomersGeneralInformation.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/customers/:id/conditions',
                name: 'customers.conditions',
                component: () => import('@/views/pages/customers/show/components/CustomersConditions.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/customers/:id/diseases',
                name: 'customers.diseases',
                component: () => import('@/views/pages/customers/show/components/CustomersDiseases.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/customers/:id/carework',
                name: 'customers.carework',
                component: () => import('@/views/pages/customers/show/components/CustomersCarework.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/customers/:id/tasks',
                name: 'customers.tasks',
                component: () => import('@/views/pages/customers/show/components/CustomerTasks.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/customers/:id/invoices',
                name: 'customers.invoices',
                component: () => import('@/views/pages/customers/show/components/CustomerInvoices.vue'),
                meta: {
                    middleware: [auth]
                },
            },
            {
                path: '/customers/:id/attachments',
                name: 'customers.attachments',
                component: () => import('@/views/pages/customers/show/components/CustomersAttachments.vue'),
                meta: {
                    middleware: [auth]
                },
            },
        ],
    },
    /** Contracts **/
    {
        path: '/contracts',
        name: 'contracts',
        component: () => import('@/views/pages/contracts/list/ContractsList.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/rotations',
        name: 'rotations',
        component: () => import('@/views/pages/rotations/list/RotationsList.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/contracts/:id',
        name: 'contracts.view',
        redirect: '/contracts/:id/rotations',
        component: () => import('@/views/pages/contracts/view/ContractsView.vue'),
        meta: {
            middleware: [auth]
        },
        children: [
            {
                path: '/contracts/:id/rotations',
                name: 'contracts.rotations',
                component: () => import('@/views/pages/contracts/view/components/ContractsViewJobs.vue'),
            },
            {
                path: '/contracts/:id/calendar',
                name: 'contracts.calendar',
                component: () => import('@/views/pages/contracts/view/components/ContractsViewCalendar.vue'),
            },
            {
                path: '/contracts/:id/invoices',
                name: 'contracts.invoices',
                component: () => import('@/views/pages/contracts/view/components/ContractsViewInvoiceRotations.vue'),
            },
        ],
    },
    {
        path: '/contracts/create',
        name: 'contracts.create',
        component: () => import('@/views/pages/contracts/create/ContractsCreate.vue'),
        meta: {
            middleware: [auth]
        }
    },
    /** Tasks **/
    {
        path: '/tasks',
        name: 'tasks',
        component: () => import('@/views/pages/tasks/list/TasksList.vue'),
        meta: {
            middleware: [auth]
        }
    },
    /** invoices **/
    {
        path: '/invoices',
        name: 'invoices',
        component: () => import('@/views/pages/invoices/list/InvoicesList.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/Dashboard.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: () => import('@/views/pages/projects/Projects.vue'),
        meta: {
            middleware: [auth]
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/pages/settings/Settings.vue'),
        redirect: '/settings/import-data',
        meta: {
            middleware: [auth]
        },
        children: [
            {
                path: '/settings/roles',
                name: 'settings.roles',
                component: () => import('@/views/pages/settings/components/SettingsRolePermissionsEditor.vue'),
            },
            {
                path: '/settings/services',
                name: 'settings.services',
                component: () => import('@/views/pages/settings/services/SettingsServices.vue'),
            },
            {
                path: '/settings/import-data',
                name: 'settings.import-data',
                component: () => import('@/views/pages/settings/components/SettingsImportData.vue'),
            }
        ],
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/pages/profile/Profile.vue'),
        meta: {
            middleware: [auth]
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to: any, from: any, next: any) => {
    const middleware = to.meta.middleware;
    const context = {to, from, next};

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router
