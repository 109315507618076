import Vue from 'vue'

export default {
    fetchCustomers: function (filters = {}) {
        return Vue.$http.get('customers', {
            params: filters
        });
    },

    storeCustomer: function (customer) {
        return Vue.$http.post('customers', customer)
    },

    getCustomersDictionary: function () {
        return Vue.$http.get('customers/dictionary');
    },

    fetchCustomer: function (customer_id) {
        return Vue.$http.get('customers/' + customer_id);
    },

    deleteCustomer: function (customer_id) {
        return Vue.$http.delete('customers/' + customer_id);
    },

    updateCustomer: function (customer_id, data) {
        return Vue.$http.patch('customers/' + customer_id, data);
    },

    submitCustomerConditionsBlank: function (customer_id, data) {
        return Vue.$http.patch('customers/' + customer_id + '/conditions', data);
    },

    submitCustomerCareworkBlank: function (customer_id, data) {
        return Vue.$http.patch('customers/' + customer_id + '/carework', data);
    },

    submitCustomerDiseasesBlank: function (customer_id, data) {
        return Vue.$http.patch('customers/' + customer_id + '/diseases', data);
    },

    fetchCustomerConditionsBlank: function (customer_id, filters) {
        return Vue.$http.get('customers/' + customer_id + '/conditions', {
            params: filters
        });
    },

    fetchCustomerCareworkBlank: function (customer_id, filters) {
        return Vue.$http.get('customers/' + customer_id + '/carework', {
            params: filters
        });
    },

    fetchCustomerDiseaseBlank: function (customer_id, filters) {
        return Vue.$http.get('customers/' + customer_id + '/diseases', {
            params: filters
        });
    },

    fetchClassifications: function () {
        return Vue.$http.get('customers/classifications');
    },

    fetchCustomerClassifications: function (customer_id) {
        return Vue.$http.get('customers/' + customer_id + '/classifications');
    },

    updateCustomerClassifications: function (customer_id, form) {
        return Vue.$http.patch('customers/' + customer_id + '/classifications', {classifications: form});
    },

    fetchCustomerBlankAvailableDates: function (customer_id, type) {
        return Vue.$http.get('customers/' + customer_id + '/blanks/' + type + '/dates');
    },
}
