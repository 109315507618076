// @ts-ignore
const state = {
    user: {
        name: 'Alex'
    },
    token: null,
    roles: [],
    permissions: [],
};

// @ts-ignore
const getters = {
    user(state: any) {
        return state.user;
    },
    authUserId(state: any) {
        return state.user ? state.user.id : null;
    },
    token(state: any) {
        return state.token;
    },
    check(state: any) {
        return !!state.token;
    },
    roles(state: any) {
        return state.roles;
    },
    role(state: any) {
        return state.roles.length > 0 ? state.roles[0] : 'user'
    },
    permissions(state: any) {
        return state.permissions;
    }
};


// @ts-ignore
const actions = {
    /** On Success Login **/
    saveAuthUser(context, data) {
        context.commit('saveAuthUser', data);
    },
    /** On Logout **/
    purgeAuthUser(context, data) {
        context.commit('purgeAuthUser', data);
    }
};

// @ts-ignore
const mutations = {
    purgeAuthUser: function (state: any) {
        state.user = null;
        state.token = null;
        state.roles = [];
        state.permissions = [];
    },
    saveAuthUser: function (state: any, payload: any) {
        state.user = payload.user;
        state.token = payload.token;
        state.roles = payload.roles;
        state.permissions = payload.permissions;
    },
};

export const auth = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
